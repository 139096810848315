import { defineComponent } from "vue";
import { useStore } from "vuex";
export default defineComponent({
    name: "BaseFiveRowCol",
    props: {
        loading: {
            type: Boolean,
            default: false,
        },
        products: { type: Array },
    },
    setup: function () {
        var store = useStore();
        return {
            store: store,
        };
    },
});
