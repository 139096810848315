import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withModifiers as _withModifiers, createVNode as _createVNode, createElementBlock as _createElementBlock, Transition as _Transition, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function (n) { return (_pushScopeId("data-v-6beb5cc3"), n = n(), _popScopeId(), n); };
var _hoisted_1 = ["src"];
var _hoisted_2 = { class: "product_name" };
var _hoisted_3 = { class: "fw_600 fs_16 lh_16 text-center mx-1" };
var _hoisted_4 = {
    key: 0,
    class: "fw_300 fs_16 lh_16 text-center mx-1",
    style: { "color": "#969696" }
};
var _hoisted_5 = { style: { "font-size": "0.775em" } };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    var _component_base_ribbon = _resolveComponent("base-ribbon");
    var _component_base_icon = _resolveComponent("base-icon");
    var _component_base_flex = _resolveComponent("base-flex");
    return (_openBlock(), _createBlock(_Transition, {
        name: "fade",
        mode: "out-in"
    }, {
        default: _withCtx(function () {
            var _a, _b, _c, _d, _e;
            return [
                ((_ctx.currentRouteName === 'account-wishlist' && _ctx.isWishlist) ||
                    _ctx.currentRouteName !== 'account-wishlist')
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: "col py-5 card_div",
                        onClick: _cache[2] || (_cache[2] = function ($event) { var _a; return (_ctx.addToCart((_a = _ctx.product) === null || _a === void 0 ? void 0 : _a.id)); }),
                        role: "button"
                    }, [
                        ((_a = _ctx.product) === null || _a === void 0 ? void 0 : _a.promotion)
                            ? (_openBlock(), _createBlock(_component_base_ribbon, { key: 0 }, {
                                default: _withCtx(function () {
                                    var _a, _b;
                                    return [
                                        _createTextVNode(_toDisplayString((_b = (_a = _ctx.product) === null || _a === void 0 ? void 0 : _a.promotion) === null || _b === void 0 ? void 0 : _b.percentage) + " % OFF ", 1)
                                    ];
                                }),
                                _: 1
                            }))
                            : _createCommentVNode("", true),
                        _createVNode(_component_base_flex, { class: "position-relative" }, {
                            default: _withCtx(function () {
                                var _a, _b;
                                return [
                                    _createElementVNode("img", {
                                        src: ((_a = _ctx.product) === null || _a === void 0 ? void 0 : _a.images[0]) ? (_b = _ctx.product) === null || _b === void 0 ? void 0 : _b.images[0].image_path : 'https://via.placeholder.com/640x360',
                                        class: _normalizeClass(["object_fit_image easeload", [_ctx.isImageLoad && 'opacity_1']]),
                                        loading: "lazy",
                                        onLoad: _cache[0] || (_cache[0] = function ($event) { return (_ctx.isImageLoad = true); })
                                    }, null, 42, _hoisted_1),
                                    (_ctx.store.getters.isUserAuthenticated)
                                        ? (_openBlock(), _createBlock(_component_base_icon, {
                                            key: 0,
                                            fixedHW: "30",
                                            iconName: _ctx.isWishlist ? 'bi bi-heart-fill' : 'bi bi-heart',
                                            noBorder: "",
                                            isBrownBackground: "",
                                            isWhiteColor: "",
                                            style: { "position": "absolute", "top": "8px", "left": "8px" },
                                            onClick: _cache[1] || (_cache[1] = _withModifiers(function ($event) { var _a; return (_ctx.wishlistAction((_a = _ctx.product) === null || _a === void 0 ? void 0 : _a.id)); }, ["stop"]))
                                        }, null, 8, ["iconName"]))
                                        : _createCommentVNode("", true),
                                    _createVNode(_component_base_flex, {
                                        class: "product_status",
                                        style: { "position": "absolute", "top": "8px", "right": "8px" }
                                    }, {
                                        default: _withCtx(function () { return [
                                            _createVNode(_component_base_flex, { class: "flex-row align-items-center product_status_tag" }, {
                                                default: _withCtx(function () {
                                                    var _a, _b;
                                                    return [
                                                        _createElementVNode("span", {
                                                            class: _normalizeClass(["fw_600 lh_16 text-center fw_700", [
                                                                    ((_a = _ctx.product) === null || _a === void 0 ? void 0 : _a.out_of_stock_status) === 'In Stock'
                                                                        ? 'text-success'
                                                                        : 'text-danger',
                                                                ]])
                                                        }, _toDisplayString(_ctx.$t((_b = _ctx.product) === null || _b === void 0 ? void 0 : _b.out_of_stock_status)), 3)
                                                    ];
                                                }),
                                                _: 1
                                            })
                                        ]; }),
                                        _: 1
                                    })
                                ];
                            }),
                            _: 1
                        }),
                        _createElementVNode("div", null, [
                            _createVNode(_component_base_flex, { class: "text_below_image justify-content-between mt-3" }, {
                                default: _withCtx(function () {
                                    var _a;
                                    return [
                                        _createElementVNode("span", _hoisted_2, _toDisplayString((_a = _ctx.product) === null || _a === void 0 ? void 0 : _a.name), 1)
                                    ];
                                }),
                                _: 1
                            }),
                            _createElementVNode("div", null, [
                                _createElementVNode("span", _hoisted_3, _toDisplayString((_b = _ctx.product) === null || _b === void 0 ? void 0 : _b.price_format), 1),
                                ((_c = _ctx.product) === null || _c === void 0 ? void 0 : _c.promotion)
                                    ? (_openBlock(), _createElementBlock("del", _hoisted_4, [
                                        _createElementVNode("small", _hoisted_5, _toDisplayString((_e = (_d = _ctx.product) === null || _d === void 0 ? void 0 : _d.promotion) === null || _e === void 0 ? void 0 : _e.price_format), 1)
                                    ]))
                                    : _createCommentVNode("", true)
                            ])
                        ])
                    ]))
                    : _createCommentVNode("", true)
            ];
        }),
        _: 1
    }));
}
