import { __awaiter, __generator } from "tslib";
import { computed, defineComponent, ref } from "vue";
import { wishlist, removeWishlist } from "@/api/WishlistApi";
import useNotyf from "@/composable/useNotyf";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { Modal } from "bootstrap";
import { audioPlayClick, getCartList, translate } from "@/core/helpers/custom";
import { addProductToCart } from "@/api/CartApi";
import { useI18n } from "vue-i18n/index";
export default defineComponent({
    name: "product-card",
    components: {},
    props: {
        product: { type: Object },
        wishlist: {
            type: Boolean,
            default: false,
        },
    },
    setup: function (props) {
        var _this = this;
        var notification = useNotyf();
        var isWishlist = ref(props.wishlist);
        var router = useRouter();
        var store = useStore();
        // const productDetail = ref<IProduct>();
        var productId = ref(0);
        var loading = ref(false);
        var addToCartItem = ref({});
        var t = useI18n().t;
        var isImageLoad = ref(false);
        var currentRouteName = computed(function () {
            return router.currentRoute.value.name;
        });
        var wishlistAction = function (product_id) { return __awaiter(_this, void 0, void 0, function () {
            var myModal;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!!store.getters.isUserAuthenticated) return [3 /*break*/, 1];
                        myModal = new Modal(document.getElementById("kt_modal_login"), {
                            keyboard: false,
                        });
                        myModal.show();
                        return [3 /*break*/, 5];
                    case 1:
                        if (!isWishlist.value) return [3 /*break*/, 3];
                        return [4 /*yield*/, removeWishlist({
                                product_id: product_id,
                            })
                                .then(function () {
                                audioPlayClick("Mouse");
                                notification.success(t("This item has been removed from your wishlist."));
                                isWishlist.value = false;
                            })
                                .catch(function () {
                                notification.error(translate("Failed attempt to removed from wishlist!"));
                            })];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 5];
                    case 3: return [4 /*yield*/, wishlist({
                            product_id: product_id,
                        })
                            .then(function () {
                            audioPlayClick("Mouse");
                            notification.success(t("This item has been added to your wishlist."));
                            isWishlist.value = true;
                        })
                            .catch(function () {
                            notification.error(translate("Failed attempt to add to wishlist!"));
                        })];
                    case 4:
                        _a.sent();
                        _a.label = 5;
                    case 5: return [2 /*return*/];
                }
            });
        }); };
        var addToCart = function (value) { return __awaiter(_this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        audioPlayClick("AddToCart");
                        addToCartItem.value = {
                            product_id: value,
                            quantity: 1,
                            options: [],
                        };
                        if (!(((_a = props.product) === null || _a === void 0 ? void 0 : _a.out_of_stock_status) === "In Stock")) return [3 /*break*/, 2];
                        return [4 /*yield*/, addProductToCart(addToCartItem.value)
                                .then(function (res) {
                                notification.success(
                                // res.data.message ?? "Add to cart successfully"
                                t("Add to cart successfully")
                                // "Success"
                                );
                            })
                                .catch(function () {
                                "_";
                            })];
                    case 1:
                        _b.sent();
                        getCartList();
                        return [3 /*break*/, 3];
                    case 2:
                        // notification.error("Product out of stock!");
                        notification.error(translate("Out of stock!"));
                        _b.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        return {
            wishlistAction: wishlistAction,
            isWishlist: isWishlist,
            currentRouteName: currentRouteName,
            store: store,
            productId: productId,
            loading: loading,
            addToCart: addToCart,
            isImageLoad: isImageLoad,
        };
    },
});
