import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function (n) { return (_pushScopeId("data-v-0bc2e76a"), n = n(), _popScopeId(), n); };
var _hoisted_1 = {
    class: "main_div row row-cols-7 justify-content-center align-content-start mt-3 position_relative px-3",
    style: {}
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]));
}
